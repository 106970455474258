<template>
  <div class="flex flex-col gap-2">
    <BackButton :route="{ name: 'Recurso'}" />
    <div v-if="filteredWorks.length" class="flex flex-col gap-1">
      <SeleccionTrabajoRow v-for="trabajo in filteredWorks" :key="trabajo.id" :trabajo="trabajo" @requireRefresh="filterWorks" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import BackButton from '@/components/shared/BackButton.vue'
import SeleccionTrabajoRow from '@/components/recurso/SeleccionTrabajoRow.vue'
export default {
  name: 'Trabajos',
  components: { BackButton, SeleccionTrabajoRow },
  data() {
    return {
      filteredWorks: [],
    }
  },

  computed: {
    ...mapGetters({
      worksAssigned: 'works/worksAssigned'
    }),
  },

  async mounted() {
    if (!this.worksAssigned) {
      await this.getWorks()
    }
    this.filterWorks()
  },

  methods: {
    ...mapActions({ getWorks: 'works/getAssigned'}),

    filterWorks() {
      this.filteredWorks = this.worksAssigned.filter(work => work.status === undefined)
    }
  }
}
</script>
