<template>
  <div class="trabajo-row">
    <!-- Encabezado -->
    <div class="flex-1 overflow-ellipsis overflow-x-hidden py-2 px-3 bg-white">
      <span class="trabajo-row-item font-nova-semibold">
        {{ trabajo.client }} / {{ trabajo.keyword }} / {{ trabajo.keyword2 }}
      </span>
    </div>
    <!-- Menu desplegable -->
    <div class="bg-white">
      <div v-if="!showMenu" @click="showMenu = !showMenu" class="flex items-center h-full cursor-pointer">
        <EllipsisVIcon class="px-3 h-5" />
      </div>
      <div v-else class="relative h-full bg-white z-20">
        <div v-if="actions === 2" class="flex h-full bg-white">
          <div
            class="icon-container bg-yellow-300"
            @click="setStatus(trabajo, 'partiallyFinished')"
          >
            P
          </div>
          <div class="icon-container bg-green-400" @click="setStatus(trabajo, 'finished')">T</div>
        </div>
        <div v-else class="flex h-full">
          <div class="icon-container bg-blue-500" @click="setStatus(trabajo, undefined)">
            <BackIcon class="px-2" />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showMenu"
      class="fixed top-0 left-0 w-full h-full bg-transparent z-10"
      @click="showMenu = false"
    ></div>
  </div>
</template>

<script>
import { mapMutations } from "vuex"
import BackIcon from '@/components/shared/icons/BackIcon.vue'
import EllipsisVIcon from '@/components/shared/icons/EllipsisVIcon.vue'
export default {
  name: 'SeleccionTrabajoRow',
  components: { BackIcon, EllipsisVIcon },
  props: {
    trabajo: { type: Object, default: null },
    actions: { type: Number, default: 2 }
  },
  data() {
    return {
      showMenu: false
    }
  },

  methods: {
    ...mapMutations({ setWorkStatus: 'works/setStatus'}),

    setStatus(trabajo, status) {
      this.setWorkStatus({ id: trabajo.id, status }),
      this.$emit('requireRefresh')
      this.showMenu = false
    }
  }
}
</script>

<style scoped>
.trabajo-row {
  @apply flex justify-between gap-1 text-lg cursor-default;
}
.trabajo-row-item {
  @apply bg-white uppercase;
  white-space: nowrap;
}
.icon-container {
  @apply flex items-center justify-center w-12 text-white cursor-pointer;
  font-family: ProximaNovaSemiBold;
  font-size: 1.75rem;
}
</style>
